import { Content } from "@components/Contact/Contact.styles"
import { WidthLimitation } from "@components/Content"
import ArrowIcon from "@images/arrow-link.svg"
import React from "react"
import { Box, Flex, Heading, Image, Link, Text } from "rebass"

export const CardVideo = ({ children, ...props }) => (
  <Box
    sx={{
      backgroundColor: "#333",
      padding: 3,
      width: "340px",
      borderRadius: 6,
      boxShadow: "card",
      cursor: "pointer",
      margin: ["5px", "20px"],
      minHeight: "400px",
    }}
    {...props}
  >
    {children}
  </Box>
)

export const ArticleWrapper = ({ children, ...props }) => (
  <Flex
    flexDirection="column"
    sx={{
      backgroundColor: "#333",
      padding: 3,
      width: "340px",
      borderRadius: 6,
      boxShadow: "card",
      cursor: "pointer",
      margin: ["5px", "20px"],
    }}
    {...props}
  >
    {children}
  </Flex>
)

export const CardTitle = ({ children, ...props }) => (
  <Heading
    {...{
      fontHeight: "16px",
      fontSize: "16px",
      fontWeight: "body",
      letterSpacing: "0px",
      color: "white",
      mb: 2,
    }}
    as="h4"
    {...props}
  >
    {children}
  </Heading>
)

export const ArticleTitle = ({ children, ...props }) => (
  <Box display="block" mt={2} textAlign="left" width="100%">
    <Heading
      {...{
        fontHeight: "16px",
        fontSize: "26px",
        fontWeight: "body",
        letterSpacing: "0px",
        color: "white",
        mb: 2,
      }}
      as="h4"
      {...props}
    >
      {children}
    </Heading>
  </Box>
)

export const CardDescription = ({ children, ...props }) => (
  <Text
    {...{
      color: "white",
      fontHeight: "18px",
      fontSize: "14px",
      fontWeight: "100",
      letterSpacing: "0px",
    }}
    as="p"
    {...props}
  >
    {children}
  </Text>
)

export const Wrapper = ({ ...props }) => (
  <Content flexWrap="wrap" justifyContent="center" {...props} />
)

export const CardContent = ({ ...props }) => <Flex mt="3" {...props} />

export const CardInformation = ({ ...props }) => (
  <Box display="block" textAlign="left" ml="3" width="80%" {...props} />
)

export const CardImage = ({ ...props }) => <Box width="20%" {...props} />

export const MidiaWrapper = props => (
  <Flex
    sx={{
      justifyContent: ["center", "center", "flex-start"],
      flexWrap: "wrap",
    }}
    {...props}
  />
)

export const ArticleImage = ({ children }) => (
  <Flex
    sx={{
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100px",
      padding: "0 40px",
    }}
  >
    {children}
  </Flex>
)

export const ImgLogo = ({ src }) => <Image src={src} />

export const ArticleLink = ({ link, children, ...props }) => (
  <Flex flex={1} alignItems="flex-end">
    <Link
      target="__blank"
      href={link}
      sx={{
        color: "#fff",
        opacity: 0.6,
        textDecoration: "none",
        transition: "opacity .2s",
        width: "auto",
        svg: {
          marginLeft: 2,
          path: {
            fill: "#fff",
          },
        },
        ":hover": {
          opacity: 1,
        },
      }}
      {...props}
    >
      {children}
      <ArrowIcon />
    </Link>
  </Flex>
)

export const ArticleContent = ({ children }) => (
  <Box width="100vw" backgroundColor="#1d1d1d" paddingY="5">
    <WidthLimitation margin="0 auto" paddingX="20px">
      {children}
    </WidthLimitation>
  </Box>
)
